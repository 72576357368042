'use client';

import { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { LightBulbIcon } from '@heroicons/react/24/outline';

export type tabItemsType = {
  id: number;
  text: string;
  icon: typeof LightBulbIcon | (({ className }: { className: string }) => JSX.Element);
};

type TabsProps = {
  tabIdx: number;
  setTabIdx: Dispatch<SetStateAction<number>>;
  tabItems: tabItemsType[];
};

export const Tabs = ({ tabIdx, setTabIdx, tabItems }: TabsProps) => {
  const handleTabClick = (idx: number) => {
    setTabIdx(idx);
  };

  return (
    <div className="flex justify-center">
      <div className="no-scrollbar flex flex-nowrap gap-0 overflow-x-auto px-4 md:gap-6 md:px-10">
        {tabItems.length > 1
          ? tabItems.map((item, idx) => (
              <button
                onClick={() => handleTabClick(idx)}
                key={item.id}
                className={clsx(
                  'group flex w-[6.5rem] flex-[0_0_auto] cursor-pointer flex-col items-center gap-4 rounded-lg transition-colors md:w-[9rem]',
                  { 'text-gradle-blue': item.id === tabIdx + 1 },
                )}
              >
                <item.icon className="size-6 transition-colors group-hover:text-gradle-blue" />
                <p className="text-body transition-colors group-hover:text-gradle-blue">{item.text}</p>
              </button>
            ))
          : null}
      </div>
    </div>
  );
};
