'use client';

import { PlusIcon, MinusIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';

interface AccordionProps {
  title: string;
  index: number;
  isOpen: boolean;
  setOpenIndex: Dispatch<SetStateAction<number>>;
  theme?: 'light' | 'dark';
  children: React.ReactNode;
}

export function Accordion({ title, index, isOpen, setOpenIndex, theme = 'light', children }: AccordionProps) {
  const handleClick = () => {
    return isOpen ? setOpenIndex(-1) : setOpenIndex(index);
  };

  return (
    <>
      <div
        onClick={handleClick}
        className={clsx(
          'flex cursor-pointer flex-col rounded-2xl',
          theme === 'dark' ? 'bg-dark-background' : 'bg-light-background-200 p-5',
        )}
      >
        <div
          className={clsx('flex items-center justify-between gap-3', theme === 'dark' ? 'text-white' : 'text-gray-1')}
        >
          <p className={clsx(theme === 'dark' ? 'text-h6' : 'text-eyebrow')}>{title}</p>
          {isOpen ? (
            <MinusIcon className={clsx('size-5 shrink-0', theme === 'dark' ? 'text-gray-4' : 'text-gradle-green')} />
          ) : (
            <PlusIcon className={clsx('size-5 shrink-0', theme === 'dark' ? 'text-gray-4' : 'text-gradle-green')} />
          )}
        </div>
        <div
          className={clsx(
            'text-body grid transition-all duration-300 ease-out',
            isOpen ? 'mt-5 grid-rows-[1fr]' : 'grid-rows-[0fr]',
            theme === 'dark' ? 'text-gray-4' : 'text-gray-3',
          )}
        >
          <div className="overflow-hidden">{children}</div>
        </div>
      </div>
      {theme === 'dark' && <hr className="border-gray-2 border-opacity-50" />}
    </>
  );
}

interface AccordionSimpleProps {
  title: string;
  index: number;
  isOpen: boolean;
  setOpenIndex: Dispatch<SetStateAction<number>>;
  children: React.ReactNode;
}

export function AccordionSimple({ title, index, isOpen, setOpenIndex, children }: AccordionSimpleProps) {
  const handleClick = () => {
    return isOpen ? setOpenIndex(-1) : setOpenIndex(index);
  };

  return (
    <div onClick={handleClick} className="flex w-full cursor-pointer flex-col rounded-2xl">
      <div className="group flex items-center justify-between gap-3 text-white transition-colors hover:text-light-blue">
        <p className={clsx('text-eyebrow', isOpen && 'text-light-blue')}>{title}</p>
        {isOpen ? (
          <MinusIcon
            className={clsx(
              'size-5 shrink-0 transition-colors group-hover:text-light-blue',
              isOpen ? 'text-light-blue' : 'text-gray-3',
            )}
          />
        ) : (
          <PlusIcon className={'size-5 shrink-0 text-gray-3 transition-colors group-hover:text-light-blue'} />
        )}
      </div>
      <div
        className={clsx(
          'text-body grid text-gray-5 transition-all duration-300 ease-out',
          isOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
        )}
      >
        <div className="overflow-hidden">{children}</div>
      </div>
    </div>
  );
}
