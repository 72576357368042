'use client';

import { ClipboardDocumentIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import Lottie, { LottieComponentProps } from 'lottie-react';
import { useState } from 'react';
import clsx from 'clsx';
import HeroNav from './HeroNav';

type ButtonIconProps = {
  logo: JSX.Element;
  gradientTo: `to-${string}`;
  gradientFrom: `from-${string}`;
};

export const ButtonIcon = ({ logo, gradientTo, gradientFrom }: ButtonIconProps) => (
  <div className={clsx('rounded-[4px] bg-gradient-to-br p-[1px]', gradientTo, gradientFrom)}>
    <div className="rounded-[3px] bg-[#1D1B1B] p-1">{logo}</div>
  </div>
);

type GradleBuildToolHeroProps = {
  title: string;
  body: string;
  lottieData: LottieComponentProps['animationData'];
  codeTitle: string;
  codeBody: JSX.Element[];
  codeCopyContent: string;
  buttonIcon: JSX.Element;
};

export default function GradleBuildToolHero({
  title,
  body,
  lottieData,
  codeTitle,
  codeBody,
  codeCopyContent,
  buttonIcon,
}: GradleBuildToolHeroProps) {
  const handleClickCopyIcon = () => {
    navigator.clipboard.writeText(codeCopyContent);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const [isCopied, setIsCopied] = useState(false);

  return (
    <section id="hero" className="py-section px-section bg-gray-1 text-white">
      <div className="max-w-section relative flex flex-col items-center gap-6">
        <div className="flex max-w-[48rem] flex-col items-center justify-center gap-10 text-center">
          <div className="h-32 w-36 rounded-2xl">
            <Lottie animationData={lottieData} autoPlay={true} loop={false} />
          </div>
          <div className="mb-4 flex flex-col gap-6">
            <h1 className="text-4xl text-h2 font-bold">{title}</h1>
            <p className="text-body text-gray-4">{body}</p>
          </div>
        </div>
        <HeroNav />
        <div className="w-full max-w-[48rem] rounded-2xl border border-dark-background">
          <div className="flex items-center justify-between rounded-t-2xl border-b border-dark-background px-6 py-4">
            <button className="text-input-label flex items-center justify-center gap-2 rounded-lg border border-dark-background bg-gradient-to-r from-[#1D1B1B] from-40% to-[#000] p-2 text-white">
              {buttonIcon}
              {codeTitle}
            </button>

            <button onClick={handleClickCopyIcon} className="rounded-lg p-1 text-white hover:bg-dark-background">
              {isCopied ? (
                <CheckCircleIcon className="size-6 text-[#00C851]" />
              ) : (
                <ClipboardDocumentIcon className="size-6 text-gray-4" />
              )}
            </button>
          </div>
          <div className="flex gap-5 p-6">
            <div>
              {codeBody.map((_, index) => (
                <div key={index} className="text-input-label text-right text-gray-2">
                  {index + 1}
                </div>
              ))}
            </div>
            <pre className="text-input-label no-scrollbar w-full overflow-scroll text-white">
              <code className="language-kotlin">{codeBody}</code>
            </pre>
          </div>
        </div>
      </div>
    </section>
  );
}
