import Image, { StaticImageData } from 'next/image';
import { AccordionSimple } from '@/components/Accordion';
import { Fragment, useState } from 'react';
import Link from 'next/link';
import { type FeatureTabsProps } from '@/sections/solutions/build-tools/components/feature-tabs';

export type TabContentType = {
  id: number;
  image: StaticImageData;
  title: string;
  content: string;
  link: string;
  linkText: string;
};

type TabCardProps = {
  item: TabContentType;
};

export const TabCard = ({ item }: TabCardProps) => {
  return (
    <div className="flex animate-fade-in flex-col gap-8 rounded-2xl bg-[#13171B] p-6 opacity-0 transition-opacity duration-700 md:p-8 lg:h-[31rem] lg:flex-row lg:gap-14 lg:p-14">
      <div className="flex flex-1 flex-col items-start justify-center gap-4">
        <h5 className="text-h4">{item.title}</h5>
        <p className="text-body text-left text-gray-4">{item.content}</p>
      </div>
      <div className="flex flex-1 items-center justify-center">
        <Image alt={item.title} className="self-center rounded-2xl" src={item.image} />
      </div>
    </div>
  );
};

export type TabCardAccordionType = {
  id: number;
  image: StaticImageData;
  title: string;
  accordionData: {
    title: string;
    body: string;
    link: string;
    linkText: string;
  }[];
};

export type TabCardAccordionProps = {
  item: TabCardAccordionType;
  tabItems: FeatureTabsProps['tabItems'];
};

export const TabCardAccordion = ({ tabItems, item }: TabCardAccordionProps) => {
  const [openIndex, setOpenIndex] = useState<number>(0);

  const handleNoPropClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  };

  return (
    <div className="flex animate-fade-in flex-col gap-6 rounded-3xl bg-[#13171B] px-6 py-6 opacity-0 transition-opacity duration-700 md:px-8 md:py-10 lg:gap-10 lg:gap-14 lg:px-14">
      {tabItems.length > 1 && <h4 className="text-h6">{item.title}</h4>}
      <div className="flex flex-col-reverse gap-6 lg:flex-row lg:gap-10">
        <div className="flex flex-1 flex-col items-start justify-center gap-4">
          {item.accordionData.map((accordion, index) => (
            <Fragment key={index}>
              <AccordionSimple
                title={accordion.title}
                index={index}
                isOpen={openIndex === index}
                setOpenIndex={setOpenIndex}
              >
                <div className="mb-1 flex flex-col items-start gap-4">
                  <p className="text-body max-w-[90%] text-left text-gray-4">{accordion.body}</p>
                  <Link
                    onClick={handleNoPropClick}
                    className="underline-text-transition-blue text-left"
                    href={accordion.link}
                    target="_blank"
                  >
                    {accordion.linkText}
                  </Link>
                </div>
              </AccordionSimple>
              <div className="my-3 h-[1px] w-full bg-dark-background"></div>
            </Fragment>
          ))}
        </div>
        <div className="flex flex-1 items-center justify-center">
          <Image alt={item.title} className="max-h-96 self-center rounded-2xl object-cover" src={item.image} />
        </div>
      </div>
    </div>
  );
};
