import Link from 'next/link';
import {
  MavenShortLogo,
  GradleBuildToolShortLogo,
  BazelShortLogo,
  SbtShortLogo,
  AndroidShortLogo,
} from '@/components/Logos';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';

const buildToolsNavItems = [
  { title: 'Gradle', url: '/develocity/solutions/gradle-build-tool/', icon: GradleBuildToolShortLogo },
  { title: 'Android', url: '/develocity/solutions/android/', icon: AndroidShortLogo },
  { title: 'Maven', url: '/develocity/solutions/maven/', icon: MavenShortLogo },
  { title: 'Bazel', url: '/develocity/solutions/bazel/', icon: BazelShortLogo },
  { title: 'sbt', url: '/develocity/solutions/sbt/', icon: SbtShortLogo },
];

export default function HeroNav() {
  const pathname = usePathname();
  const isActive = (url: string) => pathname.includes(url);

  return (
    <div className="no-scrollbar flex w-[calc(100%_+_40px)] max-w-fit overflow-scroll md:w-full md:transform-none md:justify-center">
      <div className="flex gap-6 px-5">
        {buildToolsNavItems.map(item => (
          <Link key={item.title} className="group flex flex-col items-center justify-center gap-2" href={item.url}>
            <div
              className={clsx(
                'flex h-14 w-16 items-center justify-center rounded-2xl border border-dark-background transition-all group-hover:border-gray-4',
                isActive(item.url) && 'border-white',
              )}
            >
              {item.title === 'sbt' ? (
                <item.icon
                  monochrome
                  className={clsx(
                    'size-6 text-gray-4 md:mix-blend-luminosity md:group-hover:text-white',
                    isActive(item.url) && 'text-white',
                  )}
                />
              ) : (
                <item.icon
                  className={clsx(
                    'size-6 text-gray-4 md:mix-blend-luminosity md:group-hover:mix-blend-normal',
                    isActive(item.url) && '!mix-blend-normal',
                  )}
                />
              )}
            </div>
            <p className="whitespace-nowrap text-center">{item.title}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}
